import { registerBlockType } from "@wordpress/blocks";
import { __ } from "@wordpress/i18n";
import { useEffect } from '@wordpress/element';

import "./style.scss";

const BlockBestsellerEdit = ({ attributes, setAttributes }) => {
	// useEfect()
	useEffect(() => {
		setAttributes({bestsellers: byvetBestsellerOptionsPage?.bestsellers});
	  }, []);

	  return (
		<div className="by__bestseller">
		{byvetBestsellerOptionsPage?.bestsellers.map((item, index)=>(
			<div className="by__bestseller__item" key={`by__bestseller__${index}`}>
				<a href={item.link} className="by__bestseller__img">
					<div  dangerouslySetInnerHTML={{__html:item.img}} />
				</a>
				<div className="by__bestseller__content">
					<h3 className="by__bestseller__name">
						<a href={item.link}>
							{item.name}
						</a>
					</h3>
					<div className="by__bestseller__price">
					{item.sale? (
						<>
							<del aria-hidden="true"><span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.price}} /></span></del>
							<ins><span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.sale}} /></span></ins>
						</>
					) : 
					(
						<>
							<span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.price }} /></span>
						</>
					)}
					</div>
					<p className="by__bestseller__description" dangerouslySetInnerHTML={{__html: item.shortDescription}} />
					<div className="by__bestseller__button">
						<div dangerouslySetInnerHTML={{__html: item.buy}} />
					</div>
				</div>
			</div>
		))}
		</div>
	);
};

const BlockBestsellerSave = ({ attributes }) => {
// console.log(byvetBestsellerOptionsPage);
	return (
		<div className="by__bestseller">
		{attributes?.bestsellers.map((item, index)=>(
			<div className="by__bestseller__item" key={`by__bestseller__${index}`}>
			<a href={item.link} className="by__bestseller__img">
				<div  dangerouslySetInnerHTML={{__html:item.img}} />
			</a>
			<div className="by__bestseller__content">
				<h3 className="by__bestseller__name">
					<a href={item.link}>
						{item.name}
					</a>
				</h3>
				<div className="by__bestseller__price">
				{item.sale? (
					<>
						<del aria-hidden="true"><span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.price}} /></span></del>
						<ins><span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.sale}} /></span></ins>
					</>
				) : 
				(
					<>
						<span class="woocommerce-Price-amount amount"><bdi dangerouslySetInnerHTML={{ __html: item.price }} /></span>
					</>
				)}

				</div>
				<p className="by__bestseller__description" dangerouslySetInnerHTML={{__html: item.shortDescription}} />
				<div className="by__bestseller__button">
					<div dangerouslySetInnerHTML={{__html: item.buy}} />
				</div>	
			</div>
		</div>
		))}
		</div>
	);
};
(() => {
	
})();

registerBlockType("moj-plugin/block-bestseller", {
	title: "ByVet Block Bestseller",
	icon: "admin-appearance",
	category: "By vet",
	attributes: {
		bestsellers: {
			type: "array",
			default: [],
		},
	},
	edit: BlockBestsellerEdit,
	save: BlockBestsellerSave,
});
